/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable github/array-foreach */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureFlagEnum, OrganizationTypeEnum } from '@remberg/global/common/core';
import { FrontendFeatureFlagEnum, LocalStorageKeys, MaterialIconsPipe } from '@remberg/global/ui';
import { FeatureFlagKey } from '@remberg/ui-core/core';
import { Permissions, SettingsPermissionsEnum, UserRole } from '@remberg/users/common/main';
import { BehaviorSubject } from 'rxjs';
import { isAccountFeatureFlagEnabled } from '../helpers/checkFeatureHelper';
import { GlobalSelectors, RootGlobalState } from '../store';
import { AppStateService } from './app-state.service';

/**
 * @deprecated should use NgRx instead
 */
@Injectable({
  providedIn: 'root',
})
export class UserRightsService {
  private MaterialIconsPipe: MaterialIconsPipe = new MaterialIconsPipe();
  private userRole$ = new BehaviorSubject<UserRole | undefined>(undefined);

  constructor(
    private appState: AppStateService,
    private store: Store<RootGlobalState>,
  ) {
    this.store.select(GlobalSelectors.selectUserRole).subscribe(this.userRole$);
  }

  public getUserRights(): UserRole | undefined {
    return this.userRole$.getValue();
  }

  public getPermissionIcon(key: string): string | undefined {
    return this.MaterialIconsPipe.transform(key);
  }

  /**
   * Needs to wait for the appState to be ready
   * @param  {any} action
   * @param  {boolean} viewCheck?
   * @returns boolean
   */
  public getPermission(action: any, viewCheck?: boolean): boolean {
    // Step 1: always true for remberg user
    if (this.isRembergAdmin()) {
      return true;
    }

    // Step 2: undefined means no access/right
    if (typeof action === 'undefined') {
      return false;
    }

    // Step 3: get all permissions from 'Session'/Local Storage
    const rights = this.getUserRights();
    const permissions = rights?.permissions;

    let result = false;

    if (action && permissions) {
      // The prefix of action (first part before "_") is the feature flag
      // The second part of action (after "_") is the exact permission
      const [first, second]: [FeatureFlagEnum, string] = action.split('_');

      // Step 4: check account feature flag
      if (!this.hasAccountFeatureFlag(first, action)) {
        return false;
      }

      // Step 5: check for 'enabled'
      if (!this.hasEnabledRight(first, permissions)) {
        return false;
      }

      // Step 6: if check for enabled, view, featureFlag return here
      if (viewCheck) {
        return true;
      }

      // Step 7: check for specific action
      if ((permissions as any)[first]) {
        result = (permissions as any)[first][second];
      }
    }

    return result;
  }

  private hasAccountFeatureFlag(rightCategory: FeatureFlagKey, action: string): boolean {
    switch (rightCategory) {
      case FrontendFeatureFlagEnum.USERS:
      case FrontendFeatureFlagEnum.ORGANIZATIONS:
      case FrontendFeatureFlagEnum.SETTINGS: {
        if (action === SettingsPermissionsEnum.SETTINGS_MANAGE_DATA_EXPORT) {
          return isAccountFeatureFlagEnabled(FeatureFlagEnum.EXPORT, this.appState);
        }
        return true;
      }
      case 'tickets':
        // Edge case since feature flag for cases does not match the rights prefix
        return isAccountFeatureFlagEnabled(FeatureFlagEnum.CASES, this.appState);
      case FeatureFlagEnum.FORMS:
        return isAccountFeatureFlagEnabled(FeatureFlagEnum.FORMS, this.appState);
      default:
        return isAccountFeatureFlagEnabled(rightCategory, this.appState);
    }
  }

  private hasEnabledRight(rightCategory: FeatureFlagKey, permissions: Permissions): boolean {
    switch (rightCategory) {
      case FeatureFlagEnum.AI_COPILOT:
      case FeatureFlagEnum.ASSETS:
      case FeatureFlagEnum.FILES:
      case FeatureFlagEnum.FORMS:
      case FeatureFlagEnum.IOT:
      case FeatureFlagEnum.WORKORDERS_MAINTENANCEPLANS:
      case FeatureFlagEnum.QRCODES:
      case FeatureFlagEnum.TASKS:
      case FeatureFlagEnum.WORKORDERS:
      case FeatureFlagEnum.PARTS:
      case FeatureFlagEnum.WORK_ORDERS_TEMPORARY:
      case FeatureFlagEnum.APPOINTMENTS_TEMPORARY:
      case FeatureFlagEnum.DASHBOARDS:
      case 'tickets':
        return this.hasEnabledRightHelper(rightCategory, permissions);
      case FeatureFlagEnum.CASES:
        // Edge case since feature flag for cases does not match the rights prefix
        return this.hasEnabledRightHelper('tickets', permissions);
      case FrontendFeatureFlagEnum.USERS:
      case FrontendFeatureFlagEnum.ORGANIZATIONS:
      case FrontendFeatureFlagEnum.SETTINGS:
      case FeatureFlagEnum.ASSETS_HIERARCHY:
      case FeatureFlagEnum.FILES_DOWNLOAD:
      case FeatureFlagEnum.CASES_REQUIRE_OEM_FEEDBACK:
      case FeatureFlagEnum.ASSETS_NOTES:
        return true;
      default:
        return false;
    }
  }

  private hasEnabledRightHelper(
    first: keyof Omit<Permissions, 'organizations' | 'users'>,
    permissions: Permissions,
  ): boolean {
    return !!permissions[first]?.enabled;
  }

  private isRembergAdmin(): boolean {
    const token = this.appState.getValue(LocalStorageKeys.TOKEN);
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      const userDetails = JSON.parse(payload);
      userDetails.id = userDetails.jti.split(':')[0]; // extracting user id from jti Session Key
      return userDetails.role === OrganizationTypeEnum.REMBERG;
    } else {
      return false;
    }
  }
}
