export enum Tickets2PopulateEnum {
  ASSIGNED_CONTACT = 'assignedContact',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  CATEGORY = 'category',
  CONTACTS = 'contacts',
  TICKETS2 = 'tickets2',
  ASSETS = 'assets',
  RELATED_ORGANIZATIONS = 'relatedOrganizations',
  RELATED_PARTS = 'relatedParts',
  CUSTOM_PROPERTY_VALUES = 'customPropertyValues',
}
