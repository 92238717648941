import { Product, productToAssetBasic } from '@remberg/assets/common/main';
import {
  contactRawToContactBasic,
  organizationRawToOrganizationBasic,
} from '@remberg/crm/common/main';

import { AssetBasic } from '@remberg/assets/common/base';
import { Complete, isDefined, isUnknownObject } from '@remberg/global/common/core';
import { UnreachableCaseError } from '@remberg/global/ui';
import {
  WorkOrder,
  WorkOrderListItem,
  WorkOrderPopulateEnum,
  isWorkOrderOverdue,
  mapToWorkOrderBasic,
} from '@remberg/work-orders/common/main';
import {
  WorkOrderOfflinePopulateEnum,
  WorkOrderOfflinePopulated,
} from './work-order-2-offline.definitions';

export function mapToWorkOrderListItem(
  workOrder: WorkOrderOfflinePopulated,
  isAssetsTemporaryFlagEnabled?: boolean,
): Complete<WorkOrderListItem> {
  return {
    _id: workOrder._id,
    counter: workOrder.counter,
    subject: workOrder.subject,
    dueDate: workOrder.dueDate,
    isOverdue: isWorkOrderOverdue(workOrder.dueDate, workOrder.statusValue.isCompleted),
    externalReference: workOrder.externalReference,
    priority: workOrder.priority,
    statusValue: workOrder.statusValue,
    status: workOrder.status,
    type: workOrder.type,
    typeValue: workOrder.typeValue,
    isParent: workOrder.isParent,
    customPropertyValues: workOrder.customPropertyValues ?? [],
    parentWorkOrderId: workOrder.parentWorkOrderId,
    checklist: workOrder.checklist ?? [],
    additionalContactInformation: workOrder.additionalContactInformation,
    parentWorkOrder: workOrder.parentWorkOrder
      ? mapToWorkOrderBasic(workOrder.parentWorkOrder)
      : undefined,
    location: workOrder.location,
    estimatedDuration: workOrder.estimatedDuration,
    isTouchedByUser: workOrder.isTouchedByUser,
    planning: workOrder.planning
      ? {
          assignedContactIds: workOrder.planning.assignedContactIds,
          assignedGroupIds: workOrder.planning.assignedGroupIds,
          assignedContacts:
            workOrder.planningContacts
              ?.map((contact) => {
                if (isUnknownObject(contact)) {
                  return contact;
                }

                return contactRawToContactBasic(contact);
              })
              .filter(isDefined) ?? [],
          assignedGroups: workOrder.planningUserGroups ?? [],
          startDate: workOrder.planning.startDate,
          endDate: workOrder.planning.endDate,
          isFullDay: workOrder.planning.isFullDay,
        }
      : undefined,

    responsibleContact: workOrder.responsibleContact
      ? contactRawToContactBasic(workOrder.responsibleContact)
      : undefined,
    responsibleGroup: workOrder.responsibleGroup,
    relatedCase: workOrder.relatedCase,
    relatedAssets: isAssetsTemporaryFlagEnabled
      ? (workOrder.relatedAssets as AssetBasic[])
      : (workOrder.relatedAssets as Product[])?.map((relatedAsset) => {
          if (isUnknownObject(relatedAsset)) {
            return relatedAsset;
          }

          return productToAssetBasic(relatedAsset);
        }),
    relatedOrganizationId: workOrder.relatedOrganizationId,
    relatedOrganization: workOrder.relatedOrganization
      ? organizationRawToOrganizationBasic(workOrder.relatedOrganization)
      : undefined,
    relatedContacts: workOrder.relatedContacts
      ?.map((contact) => {
        if (isUnknownObject(contact)) {
          return contact;
        }

        return contactRawToContactBasic(contact);
      })
      .filter(isDefined),
    createdAt: workOrder.createdAt,
    createdById: workOrder.createdById,
    createdBy: workOrder.createdBy ? contactRawToContactBasic(workOrder.createdBy) : undefined,
    updatedAt: workOrder.updatedAt,
    createdByType: workOrder.createdByType,
    updatedByType: workOrder.updatedByType,
    tenantId: workOrder.tenantId,
    organizationId: workOrder.organizationId,
    updatedById: workOrder.updatedById,
    updatedBy: workOrder.updatedBy ? contactRawToContactBasic(workOrder.updatedBy) : undefined,
  };
}

export function mapToWorkOrder(
  workOrder: WorkOrderOfflinePopulated,
  isAssetsTemporaryFlagEnabled?: boolean,
): Complete<WorkOrder> {
  return {
    _id: workOrder._id,
    counter: workOrder.counter,
    subject: workOrder.subject,
    description: workOrder.description,
    additionalContactInformation: workOrder.additionalContactInformation,
    customProperties: [],
    dueDate: workOrder.dueDate,
    externalReference: workOrder.externalReference,
    priority: workOrder.priority,
    status: workOrder.status,
    type: workOrder.type,
    isParent: workOrder.isParent,
    parentWorkOrder: workOrder.parentWorkOrder,
    location: workOrder.location,
    planning: workOrder.planning
      ? {
          assignedContacts: [
            ...(workOrder.planningContacts
              ?.map((contact) => {
                if (isUnknownObject(contact)) {
                  return contact;
                }

                return contactRawToContactBasic(contact);
              })
              .filter(isDefined) ?? []),
          ],
          assignedGroups: workOrder.planningUserGroups ?? [],
          assignedContactIds: workOrder.planning.assignedContactIds,
          assignedGroupIds: workOrder.planning.assignedGroupIds,
          startDate: workOrder.planning.startDate,
          endDate: workOrder.planning.endDate,
          isFullDay: workOrder.planning.isFullDay,
        }
      : undefined,

    checklist: workOrder.checklist ?? [],
    estimatedDuration: workOrder.estimatedDuration,
    responsibleContact: workOrder.responsibleContact
      ? contactRawToContactBasic(workOrder.responsibleContact)
      : undefined,
    responsibleGroup: workOrder.responsibleGroup,
    relatedCase: workOrder.relatedCase,
    relatedAssets: isAssetsTemporaryFlagEnabled
      ? (workOrder.relatedAssets as AssetBasic[])
      : (workOrder.relatedAssets as Product[])?.map((relatedAsset) => {
          if (isUnknownObject(relatedAsset)) {
            return relatedAsset;
          }

          return productToAssetBasic(relatedAsset);
        }),
    relatedOrganization: workOrder.relatedOrganization
      ? organizationRawToOrganizationBasic(workOrder.relatedOrganization)
      : undefined,
    relatedContacts:
      workOrder.relatedContacts
        ?.map((contact) => {
          if (isUnknownObject(contact)) {
            return contact;
          }

          return contactRawToContactBasic(contact);
        })
        .filter(isDefined) ?? [],
    createdAt: workOrder.createdAt,
    createdById: workOrder.createdById,
    createdBy: workOrder.createdBy ? contactRawToContactBasic(workOrder.createdBy) : undefined,
    updatedAt: workOrder.updatedAt,
    createdByType: workOrder.createdByType,
    updatedByType: workOrder.updatedByType,
    tenantId: workOrder.tenantId,
    organizationId: workOrder.organizationId,
    updatedById: workOrder.updatedById,
    updatedBy: workOrder.updatedBy ? contactRawToContactBasic(workOrder.updatedBy) : undefined,
  };
}

export function mapToWorkOrderOfflinePopulate(
  populate?: WorkOrderPopulateEnum[],
): Record<WorkOrderOfflinePopulateEnum, true> | undefined {
  if (!populate) {
    return undefined;
  }
  return populate.reduce(
    (acc, p) => {
      switch (p) {
        case WorkOrderPopulateEnum.CREATED_BY:
          acc[WorkOrderOfflinePopulateEnum.CREATED_BY] = true;
          break;
        case WorkOrderPopulateEnum.UPDATED_BY:
          acc[WorkOrderOfflinePopulateEnum.UPDATED_BY] = true;
          break;
        case WorkOrderPopulateEnum.RELATED_ORGANIZATION:
          acc[WorkOrderOfflinePopulateEnum.RELATED_ORGANIZATION] = true;
          break;
        case WorkOrderPopulateEnum.RELATED_CONTACTS:
          acc[WorkOrderOfflinePopulateEnum.RELATED_CONTACTS] = true;
          break;
        case WorkOrderPopulateEnum.RELATED_ASSETS:
          acc[WorkOrderOfflinePopulateEnum.RELATED_ASSETS] = true;
          break;
        case WorkOrderPopulateEnum.RELATED_CASE:
          acc[WorkOrderOfflinePopulateEnum.RELATED_CASE] = true;
          break;
        case WorkOrderPopulateEnum.PARENT_WORK_ORDER:
          acc[WorkOrderOfflinePopulateEnum.PARENT_WORK_ORDER] = true;
          break;
        case WorkOrderPopulateEnum.PLANNING:
          acc[WorkOrderOfflinePopulateEnum.PLANNING_ASSIGNED_CONTACTS] = true;
          acc[WorkOrderOfflinePopulateEnum.PLANNING_ASSIGNED_GROUPS] = true;
          break;
        case WorkOrderPopulateEnum.STATUS:
          acc[WorkOrderOfflinePopulateEnum.STATUS] = true;
          break;
        case WorkOrderPopulateEnum.RESPONSIBLE_CONTACT:
          acc[WorkOrderOfflinePopulateEnum.RESPONSIBLE_CONTACT] = true;
          break;
        case WorkOrderPopulateEnum.TYPE:
          acc[WorkOrderOfflinePopulateEnum.TYPE] = true;
          break;
        case WorkOrderPopulateEnum.RESPONSIBLE_GROUP:
          acc[WorkOrderOfflinePopulateEnum.RESPONSIBLE_GROUP] = true;
          break;
        case WorkOrderPopulateEnum.CUSTOM_PROPERTIES:
          // CustomProperties are not supported in offline mode
          break;
        default:
          new UnreachableCaseError(p);
      }
      return acc;
    },
    {} as Record<WorkOrderOfflinePopulateEnum, true>,
  );
}
