<div class="wrapper">
  <app-search-bar
    *ngIf="isSearchBarShown$ | async"
    class="search-bar"
    [autofocus]="true"
    (searchQueryChange)="searchQueryChange.next($event)"
    [inputValue]="inputValue"
    (inputBlur)="showSearchBar$.next(false)"
  />

  <button
    mat-raised-button
    class="toolbar-item button"
    (click)="showSearchBar$.next(true)"
    matBadge="1"
    [matBadgeHidden]="(isSearchBarShown$ | async) || !inputValue"
  >
    <mat-icon>search</mat-icon>
  </button>
</div>
