import { AdvancedFilter, AdvancedFilterQuery } from '@remberg/advanced-filters/common/main';
import { AssetsFilterEnum } from '@remberg/assets/common/main';
import { FindManyOfflineQuery } from '@remberg/global/ui';

export type AssetsOfflineAdvancedFilterConfigKeys = Exclude<
  AssetsFilterEnum,
  AssetsFilterEnum.RELATED_PART
>;
export type AssetsOfflineAdvancedFilterQuery =
  AdvancedFilterQuery<AssetsOfflineAdvancedFilterConfigKeys>;
export type AssetsOfflineAdvancedFilterColumns = Exclude<
  AssetsOfflineAdvancedFilterConfigKeys,
  AssetsFilterEnum.INVOLVED_CONTACT | AssetsFilterEnum.USER_GROUP | AssetsFilterEnum.BOUNDING_BOX
>;
export type AssetsFindManyOfflineQueryInterface = FindManyOfflineQuery<
  AssetsOfflineAdvancedFilterQuery,
  AdvancedFilter<AssetsOfflineAdvancedFilterConfigKeys>
>;

export const ASSET_ROUTE_ID = 'asset_id' as const;
