export enum Tickets2FilterEnum {
  ID = '_id',

  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  CREATED_BY_TYPE = 'createdByType',
  CREATED_BY_EMAIL = 'createdByEmail',
  UPDATED_AT = 'updatedAt',
  UPDATE_BY = 'updateBy',

  PRIORITY = 'priority',
  STATUS = 'status',
  SUPPORT_EMAIL_ADDRESS = 'supportEmailAddress',

  IS_COMPLETED = 'isCompleted',
  IS_FOLLOWED = 'isFollowed',
  IS_VISIBLE_IN_PORTAL = 'isVisibleInPortal',
  SUMMARY = 'summary',
  SOLUTION = 'solution',

  ASSIGNED_CONTACT = 'assigneeContact',
  CATEGORY = 'category',

  RELATED_PART = 'relatedPart',
  RELATED_ASSET = 'relatedAsset',
  RELATED_ASSET_TYPE = 'relatedAssetType',
  RELATED_TICKET2 = 'relatedTicket2',
  RELATED_ORGANIZATION = 'relatedOrganization',
  RELATED_CONTACT = 'relatedContact',
}
